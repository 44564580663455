.notices {
  display: grid;
  grid-template-columns: 4fr 6fr;
  gap: 50px;
  height: 100%;
  padding-bottom: 26px;
  &__col {
    display: flex;
    flex-direction: column;
    &:nth-child(1) {
      max-width: 514px;
      > *:not(:last-child) {
        margin-bottom: 50px;
      }
    }
    > h1 {
      font-weight: 500;
      font-size: 60px;
      margin-bottom: 40px;
    }
    > span {
      font-size: 35px;
      margin-bottom: 24px;
      font-weight: 500;
    }
  }
}

.lessons {
  display: flex;
  flex-direction: column;
  > *:not(:last-child) {
    margin-bottom: 24px;
  }
}