.gallery {
  width: 100%;
  border-radius: 16px;
  flex-grow: 1;
  overflow: hidden;
  &_small {
    max-height: 429px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &__slider {
    height: 100%;
    &-item {
      display: flex;
    }
  }
}