.weather {
  background: linear-gradient(153.2deg, rgba(166, 110, 239, 0.9) 13.49%, rgba(139, 99, 236, 0.9) 37.32%, rgba(91, 100, 240, 0.9) 79.93%);
  border-radius: 18px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 514px;
  overflow: hidden;
  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
    img {
      width: 100%;
      height: 100%;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(153.2deg, rgba(166, 110, 239, 0.9) 13.49%, rgba(139, 99, 236, 0.9) 37.32%, rgba(91, 100, 240, 0.9) 79.93%);
      z-index: 1;
    }
  }
  &__info {
    margin-bottom: 100px;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    > strong {
      font-size: 45px;
      color: #FFFFFF;
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
    }
    > span {
      font-size: 32px;
      color: rgba(255, 255, 255, 0.8);
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
    }
  }
}

.info {
  display: flex;
  align-items: flex-start;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 104.83%);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(9px);
  padding: 20px;
  border-radius: 12px;
  &__image {
    max-width: 172px;
    max-height: 172px;
    width: 172px;
    margin-top: -52px;
    margin-right: 24px;
    img {
      width: 100%;
      filter: drop-shadow(0px 8.69712px 20.8731px rgba(0, 0, 0, 0.12));
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    strong {
      font-size: 84px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1em;
      color: #FFFFFF;
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
      line-height: 1;
      margin-bottom: 8px;
    }
    span {
      &:nth-child(2) {
        font-size: 30px;
        color: #FFFFFF;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
        margin-bottom: 5px;
      }
      &:nth-child(3) {
        font-size: 24px;
        color: rgba(255, 255, 255, 0.8);
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
      }
    }
  }
  &__humidity {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px 24px;
    border-radius: 0px 10px 0px 15px;
    background: #2DBF8E;
    strong {
      font-weight: 600;
      font-size: 32px;
      color: #FFFFFF;
    }
  }
}