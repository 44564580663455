.lesson {
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: linear-gradient(180deg, #F8F8F8 0%, #F8F8F8 0.01%, #FDFDFD 100%);
  border-radius: 16px;
  opacity: 0;
  transition: 0.5s;
  transform: translateX(-50px);
  &_animated {
    opacity: 1;
    transform: translateX(0);
  }
  &_active {
    background: linear-gradient(180deg, #A56EF2 0%, #A36EF2 0.01%, #6466EF 100%);
    box-shadow: 0px 16px 35px rgba(106, 103, 240, 0.16);
    span {
      color: #ffffff!important;
    }
  }
  span {
    &:nth-child(1) {
      font-weight: 500;
      font-size: 35px;
      &:not(:last-child) {
        margin-bottom: 7px;
      }
    }
    &:nth-child(2) {
      font-size: 30px;
      color: #525252;
    }
  }
  &_alt {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > span {
      margin-bottom: 0!important;
      font-size: 35px!important;
      font-weight: 500!important;
      &:nth-child(2) {
        color: #000000;
      }
    }
  }
}