.weather {
  display: flex;
  flex-direction: column;
  &__row {
    display: flex;
    gap: 25px;
    &:not(:last-child) {
      margin-bottom: 32px;
    }
    &:nth-child(2) {
      flex-grow: 1;
    }
  }
}