.calendar {
  display: flex;
  flex-direction: column;
  > span {
    font-weight: 500;
    font-size: 35px;
    margin-bottom: 24px;
  }
  &__items {
    > *:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}