@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  text-decoration: none;
  position: relative;
  font-family: 'Poppins';
  box-sizing: border-box;
  line-height: 1.5;
}

strong {
  font-weight: 500;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-exit {
  display: none!important;
  opacity: 1;
}
.fade-exit-active {
  display: none!important;
  opacity: 0;
  transition: opacity 500ms ease-in;
}