.layout {
  display: grid;
  grid-template-columns: 2.6fr 7.4fr;
  height: 100vh;
  &__content {
    padding: 50px;
    padding-bottom: 24px;
    overflow: hidden;
    > div {
      width: 100%;
      height: 100%;
    }
  }
  h1 {
    font-weight: 500;
    font-size: 60px;
    color: #000000;
    margin-bottom: 40px;
  }
  @media (max-width: 1800px) {
    > * {
      zoom: 0.75;
    }
  }
}