.weather {
  flex-grow: 1;
  padding: 35px;
  background: linear-gradient(180deg, #F8F8F8 0%, #F8F8F8 0.01%, rgba(248, 248, 248, 0.27) 100%);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  $p: &;
  &__degrees {
    font-weight: 500;
    font-size: 75px;
    margin-bottom: 48px;
  }
  &__image {
    max-width: 106px;
    max-height: 106px;
    width: 106px;
    margin-bottom: 48px;
    img {
      width: 100%;
    }
  }
  &__time {
    font-size: 32px;
    margin-bottom: 5px;
  }
  &__date {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.7);
  }
  &__humidity {
    padding: 3px 24px;
    border-radius: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    strong {
      font-weight: 600;
      font-size: 32px;
      color: #FFFFFF;
    }
  }
  &__title {
    font-size: 30px;
    margin-bottom: 12px;
  }
  &_alt {
    align-items: flex-start;
    padding: 24px;
    #{$p}__image {
      max-width: 93px;
      max-height: 93px;
      width: 93px;
      margin-bottom: 12px;
    }
    #{$p}__degrees {
      margin-bottom: 12px;
      line-height: 1;
    }
  }
}